#app {
  display: flex;
  flex-direction: column;
  opacity: 0;
}

.content {
  display: flex;
  flex-direction: column;
  margin-bottom: vw(48px, 375px);
}

.title {
  font-family: $font-pp-rader;
  text-transform: uppercase;
  margin: 0 0 vw(72px, 375px);
  &-paragraph {
    display: block;
    line-height: 0.75;
    overflow: hidden;
  }
  & .js-title {
    display: block;
  }
  &-1 {
    font-size: vw(24px, 375px);
    margin-bottom: vw(6px, 375px);
  }
  &-2 {
    position: relative;
    font-size: vw(48px, 375px);
  }
  & .trademark {
    position: absolute;
    top: vw(-5px, 375px);
    font-size: vw(18px, 375px);
  }
}

.intro {
  & .pre-wrapper {
    margin-bottom: vw(24px, 375px);
  }
}

.projects-thumbnail {
  --clipStart: 0%;
  --clipEnd: 0%;
  position: relative;
  display: none;
  width: vw(288px);
  aspect-ratio: 4/5;
  border-radius: vw(10px);
  // clip-path: polygon(
  //   0 var(--clipEnd),
  //   100% var(--clipEnd),
  //   100% var(--clipStart),
  //   0% var(--clipStart)
  // );
  clip-path: polygon(
    var(--clipEnd) 0,
    var(--clipStart) 0,
    var(--clipStart) 100%,
    var(--clipEnd) 100%
  );
  margin-top: calc(#{vh(50px)} + #{vw(25px)});
  margin-left: vw(144px);
  overflow: hidden;
  translate: calc(var(--posX) * #{vw(-144px)}) calc(var(--posY) * #{vw(-30px)});
  & .thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    user-select: none;
    & img {
      width: auto;
      height: 100%;
    }
    &.is-active {
      opacity: 1;
    }
  }
}

.pre-wrapper {
  overflow: hidden;
  margin-bottom: vw(24px, 375px);
}

.pre {
  display: block;
  font-size: vw(10px, 375px);
  text-transform: uppercase;
  color: $color-brown-50;
  // margin-bottom: vw(24px, 375px);
}

.about__copy {
  font-size: vw(14px, 375px);
  line-height: 1.35;
  margin: 0;
}

.about-desktop {
  display: none;
}

.about-mobile {
  display: block;
}

.js-about-line {
  display: block;
  overflow: hidden;
  & span {
    display: block;
    transform: translate(0, 101%);
  }
}

.projects {
  margin-top: vw(36px, 375px);
  & .pre-wrapper {
    margin-bottom: vw(24px, 375px);
  }
  &__list {
    &.is-hovering {
      & .projects__list-item {
        opacity: 0.2;
        transition: opacity $sineInOut;
        &.is-active {
          opacity: 1;
          transition: none;
          & .project__name-wrapper {
            translate: 0 0;
          }
        }
      }
    }

    &-item {
      overflow: hidden;
      & .project__link {
        padding: vw(7px, 375px) 0;
      }
    }
  }
}

body.is-focusing .project__link:focus {
  & .project__name-wrapper {
    translate: 0 0;
  }
}

.project {
  &__link {
    display: grid;
    grid-template-columns: vw(32px, 375px) 1fr auto;
    font-size: vw(14px, 375px);
    line-height: 0.9;
    &.js-project-link {
      transform: translate(0, 101%);
    }
  }
  &__index,
  &__year {
    color: $color-brown-50;
  }
  &__name {
    position: relative;
    font-weight: 900;
    overflow: hidden;
    &-wrapper {
      display: block;
      translate: vw(-12px, 375px) 0;
      transition: translate $sineInOut;
    }
  }
}

.contacts {
  margin-top: vw(65px, 375px);
  &__list-item {
    overflow: hidden;
    & .js-contact-link {
      display: block;
    }
    &.item-phone {
      margin-bottom: vw(12px, 375px);
    }
  }
}

.contact {
  &__link {
    display: block;
    overflow: hidden;
    font-size: vw(14px, 375px);
    &:hover {
      & .contact__link-wrapper {
        translate: 0 0;
      }
    }
    &-wrapper {
      display: block;
      translate: vw(-12px, 375px) 0;
      transition: translate $sineInOut;
    }
  }
}

body.is-focusing .contact__link:focus {
  & .contact__link-wrapper {
    translate: 0 0;
  }
}

.background-year {
  position: absolute;
  bottom: vh(-93px);
  right: vh(-140px);
  z-index: -1;
  width: vh(688px);
  height: vh(572px);
  opacity: 0.35;
  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
  }
  & .year-svg {
    height: 100%;
  }
  & .js-year-light {
    & path {
      stroke: $color-brown-washed;
    }
  }
  & .js-year-dark {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    & path {
      stroke: $color-brown;
      stroke-width: 1.25;
    }
  }
}

.footer {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer__copy {
  font-size: vw(10px, 375px);
  text-transform: uppercase;
  color: $color-brown-50;
  margin: 0;
}

@media (min-width: $breakpoint-regular) {
  .content {
    display: grid;
    grid-template-columns: vw(504px) vw(720px) 1fr;
    margin-bottom: vw(24px);
  }

  .pre-wrapper {
    margin-bottom: vw(48px);
  }

  .pre {
    font-size: vw(10px);
  }

  .title {
    margin: 0;
    &-1 {
      font-size: vw(48px);
      margin-bottom: vw(6px);
    }
    &-2 {
      font-size: vw(96px);
    }
    & .trademark {
      top: vw(-8px);
      font-size: vw(36px);
    }
  }

  .intro {
    & .pre-wrapper {
      margin-bottom: vw(24px);
    }
  }

  .about__copy {
    font-size: vw(16px);
    max-width: vw(576px);
  }

  .about-desktop {
    display: block;
  }

  .about-mobile {
    display: none;
  }

  .projects-thumbnail {
    display: block;
  }

  .projects {
    margin-top: calc(#{vh(130px)} + #{vw(25px)});
    & .pre-wrapper {
      margin-bottom: vw(36px);
    }
    &__list {
      max-width: vw(576px);
      &-item {
        & .project__link {
          padding: vw(10px) 0;
          line-height: 0.85;
        }
      }
    }
  }

  .project {
    &__link {
      grid-template-columns: vw(44px) 1fr auto;
      font-size: vw(16px);
    }
    &__name {
      &-wrapper {
        translate: vw(-15px) 0;
      }
    }
  }

  .contacts {
    margin-top: 0;
    &__list-item {
      &.item-phone {
        margin-bottom: vw(24px);
      }
    }
  }

  .contact {
    &__link {
      font-size: vw(16px);
      &-wrapper {
        translate: vw(-15px) 0;
      }
    }
  }

  .background-year {
    right: vh(-22px);
    opacity: 0.5;
  }

  .footer__copy {
    font-size: vw(12px);
  }
}
