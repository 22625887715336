*,
*:before,
*:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // remove highlight color
}

::-moz-selection {
  /* Code for Firefox */
  color: $color-cream;
  background: $color-brown;
}

::selection {
  color: $color-cream;
  background: $color-brown;
}

html,
body {
  font-weight: normal;
  font-smooth: always;

  -webkit-font-smoothing: antialiased;
  -khtml-font-smoothing: antialiased;
  -apple-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  color: $color-brown;

  background-color: $color-white;

  font-family: $font-pp-radio;
  margin: 0;
}

main {
  position: relative;
  // top: 0;
  // left: 0;
  // z-index: 1;

  width: 100%;
  height: 100%;
  min-height: 100vh;
  // overflow: hidden;

  padding: vw(36px, 375px) vw(16px, 375px) vw(12px, 375px);
}

a {
  color: $color-brown;
  text-decoration: none;
  &:focus {
    outline: none;
  }
}

body.is-focusing a:focus {
  outline: solid 1px $color-brown;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

@media (min-width: $breakpoint-regular) {
  main {
    padding: vw(68px) vw(72px) vw(36px);
  }
}
