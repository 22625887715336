@mixin font-face-swap($name, $folder, $file, $weight: normal, $style: normal) {
  @font-face {
    font-family: $name;
    font-weight: $weight;
    font-style: $style;

    src: url("../assets/fonts/#{$folder}/#{$file}.woff2") format("woff2"),
      url("../assets/fonts/#{$folder}/#{$file}.woff") format("woff");
    font-display: swap;
  }
}

@include font-face-swap("PP Rader", "pp-rader", "pp-rader-bold", 600);
@include font-face-swap(
  "PP Radio Grotesk",
  "pp-radio-grotesk",
  "pp-radio-grotesk-regular"
);
@include font-face-swap(
  "PP Radio Grotesk",
  "pp-radio-grotesk",
  "pp-radio-grotesk-black",
  900
);
