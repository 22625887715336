$base-font-size: 16px;
$breakpoint-narrow: 480px;
$breakpoint-regular: 768px;
$breakpoint-wide: 1024px;

// fonts
$font-pp-rader: "PP Rader", sans-serif;
$font-pp-radio: "PP Radio Grotesk", sans-serif;

// colors
$color-white: #f6f0e5;
$color-cream: #e7dece;
$color-brown: #302218;
$color-brown-washed: #e3dbd1;
$color-brown-50: rgba(48, 34, 24, 0.5);

// eases
$easeInOut: 0.3s cubic-bezier(0.17, 0.67, 0.83, 0.67);
$sineInOut: 0.3s cubic-bezier(0.87, 0, 0.13, 1);
$navEase: 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
